<template>
    <div>
        <app-quick-links />
        <div class="text-center" style="padding-top:10px;">
            <div class="cityHeader">{{ currentCity.country.trim() !== "" ? currentCity.name + ", " + currentCity.country : currentCity.name }} <img v-b-popover.hover="'Add this city to quick links.'" class="link-like" title="Quick Links" @click="addLink" src="../assets/bookmark-filled.png" /></div>
        </div>
        <br>
        <div v-if="ready && displayMode=='indicators'" stlye="width: 100%;" class="text-center">
            <b-btn v-for="(c, i) in template" :variant="selectedCategory === i ? 'primary' : 'secondary'" @click="selectedCategory=i" :key="c.name">{{ c.name }}</b-btn>
            <br /><br />
        </div>
        <div v-if="ready && currentCity.sccActive==true" style="width: 100%" class="text-center">
            <b-btn size="small" variant="outline-success" @click="displayMode='scc'" v-if="currentCity.sccActive && displayMode=='indicators'">Sustainability Cost Curves</b-btn>
            <b-btn size="small" variant="outline-success" @click="displayMode='indicators'" v-if="currentCity.sccActive && displayMode=='scc'">Indicators</b-btn>
            <br />
        </div>
        <div v-if="!ready" class="sk-fading-circle">
            <div class="sk-circle1 sk-circle"></div>
            <div class="sk-circle2 sk-circle"></div>
            <div class="sk-circle3 sk-circle"></div>
            <div class="sk-circle4 sk-circle"></div>
            <div class="sk-circle5 sk-circle"></div>
            <div class="sk-circle6 sk-circle"></div>
            <div class="sk-circle7 sk-circle"></div>
            <div class="sk-circle8 sk-circle"></div>
            <div class="sk-circle9 sk-circle"></div>
            <div class="sk-circle10 sk-circle"></div>
            <div class="sk-circle11 sk-circle"></div>
            <div class="sk-circle12 sk-circle"></div>
        </div>
        
        <div v-if="ready && displayMode=='indicators'">
            <app-rockstrom class="rockStrom" :elId="activeCity.id" :index="selectedCategory" />
        </div>
        
        <div v-if="ready && displayMode=='indicators'" class="tableContainer">
            <table style="margin: 0 auto;">
                <tr>
                    <th>Indicators</th>
                    <th>Unit</th>
                    <th>{{ template[selectedCategory].name.indexOf('Bio') > -1 ? 'Target' : 'Goal' }}</th>
                    <th>Value</th>
                    <th>Confidence</th>
                </tr>
                <template v-for="measure in template[selectedCategory].measures">
                    <tr :key="measure">
                        <th class="titleRow" colspan="5">{{ measure.name }}</th>
                    </tr>
                    <template v-for="indicator in measure.indicators">
                        <tr class="dataRow link-like" :key="indicator.id" :index="indicator.id"
                            v-if="cityData[indicator.id] && cityData[indicator.id].value != undefined"
                        >
                            <td>{{ indicator.name }}</td>
                            <td v-html="indicator.unit"></td>
                            <td>{{getTarget(indicator)}}</td>
                            <td><b>{{ cityData[indicator.id] ? cityData[indicator.id].value : 'N/A' }}</b></td>
                            <td v-if="cityData[indicator.id]">
                                <img v-for="i in cityData[indicator.id].confidence" src="../assets/star-outline.png" :key="i"/>
                            </td>
                            <td v-else></td>
                        </tr>
                        <tr class="dataRow link-like"  :key="indicator.id" :index="indicator.id"
                            v-else-if="indicator.showNull === 1"
                        >
                            <td>{{ indicator.name }}</td>
                            <td v-html="indicator.unit"></td>
                            <td>{{getTarget(indicator)}}</td>
                            <td></td>
                            <td ></td>
                        </tr>
                    </template>
                </template>
            </table>
        </div>

        <div v-if="ready && displayMode=='scc'">
            <br />
            <app-cost-curve :oCity="currentCity" />
        </div>

        <app-footer position='' />
    </div>
</template>

<script>
    import Rockstrom from './Rockstrom.vue'
    import CostCurve from './CostCurve.vue'
    import Footer from './Footer.vue'
    import QuickLinks from './QuickLinks.vue'
    
    export default {
        data () {
            return {
                cityId: this.$route.params.id,
                selectedCategory: 0,
                displayMode: "indicators"
            }
        },
        components: {
            'appRockstrom': Rockstrom,
            'appCostCurve': CostCurve,
            'appFooter': Footer,
            'appQuickLinks': QuickLinks
        },
        methods : {
            init () {
                // console.log('app is ready - data page loading')
                this.checkCity()
                this.selectedCategory = 0
            },
            checkCity () {
                // console.log('checking city')
                if(typeof(this.activeCity) === 'undefined' || (this.activeCity.id !== this.cityId)) {
                    // console.log('mismatched IDs')
                    this.$store.commit('setActiveCityById', this.cityId)
                    this.$store.dispatch('getCityProjects')
                }
            },
            getSign (rel) {
                if(rel === 'DIRECT'){
                    return '< '
                } else if (rel === 'INVERSE') {
                    return '> '
                } else {
                    return ''
                }
            },
            addLink () {
                // console.log('adding quick link for ')
                // console.dir('activeCity')
                this.$store.commit('addQuickLink', this.activeCity)
            },
            getTarget (ind) {
                // {{indicator.showRel ? getSign(indicator.relationship) : ""}}
                //  {{ indicator.target }}
                if (ind.relationship !== "RANGE"){
                    if (ind.showRel) {
                        return this.getSign(ind.relationship) + ind.target
                    } else {
                        return ind.target
                    }
                } else {
                    return (ind.target - ind.tolerance) + " - " + (ind.target + ind.tolerance)
                }
                
            }
        },
        watch: {
            '$route' (to) {
                // this.ready = false
                this.cityId = to.params.id // 
                this.checkCity()

            },
            // cityData (to, from) {
            //     console.log('cityData changed in component')
            //     this.cityData = to.data
            // },
            ready (to, from) {
                if(to || from){
                    // console.log('page is ready')
                    // ready comes from store
                    // represents if template.length > 0 and cities.length > 0
                    this.init()
                    
                }
            }
            // id: (val) => {
            //     // console.log('id changed in data view')
            //     this.id = val
            // } 
        },
        computed: {
            dataCount () {
                return  Object.keys(this.cityData).length
            },
            currentCity () {
                if(!this.activeCity) { // if we get to the page from URL link
                    return {'name': 'Loading...', 'country': 'please wait.', 'id': 0}
                }  else { // if we get to the page from the search page
                    return this.activeCity
                }
            },
            cityData () {
                if(!this.$store.getters.getCityData[this.cityId]) {
                    // city data is not already loaded
                    this.$store.dispatch('getCityData', this.cityId)
                    return this.$store.getters.getCityData[this.cityId]
                } else {
                    return this.$store.getters.getCityData[this.cityId]
                }
                
            },
            template () {
                return this.$store.getters.getTemplate
            },
            ready () {
                if(this.cityData && this.cityData !== 'loading' && Object.keys(this.cityData).length > 0 && this.$store.getters.isReady) {
                    // console.log('cityData:')
                    // console.dir(this.cityData)
                  
                    return true
                } else {
                    return false
                }
            },
            activeCity () {
                return this.$store.getters.getActiveCity
            }
        },
        created () {
            // console.log('data page mounted with id: ' + this.cityId)
            // this.checkCity()

        },
        destroyed () {
            // console.log('data page destroyed')
        }
    }
</script>

<style scoped>
    .rockStrom {
        width: 500px;
        height: 500px;
    }
    
    .cityHeader {
        font-size: 2em;
        font-weight: bold;
    }
    
    .dataRow:hover {
        background: rgb(0,60,113);
        color: white;
    }
    
    .dataRow > td {
        padding-left: 10px;
    }
    
    .titleRow {
        border-bottom: 1px solid black;
    }
    
    .tableContainer {
        padding-bottom: 20px;
    }
</style>
<template>
    <div>
        <div class="fixed-content">
            <b-card 
                    class="contact-card"
                    title="Daniel Hoornweg"
                    sub-title="PhD">
                <p class="card-text">
                    <b>Richard Marceau Chair</b><br>
                    Faculty of Energy Systems and Nuclear Science
                </p>
                <b-link href="mailto:Daniel.Hoornweg@uoit.ca"
                        class="card-link">E-mail Inquries (Daniel.Hoornweg@uoit.ca)</b-link>
            </b-card>
            
         
            
            <app-footer position='absolute' />
        </div>
    </div>
</template>

<script>
    import Footer from './Footer.vue'

    export default {
        components: {
            'appFooter': Footer
        },
        data () {
            return {
                //
            }
        },
        watch: {
            
        },
        computed: {
            
        },
        methods: {
            //
        },
        created() {
                
            
        },
        destroyed () {
            
        }
    }
</script>

<style>
  .contact-card {
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 80%;
      margin: 0 auto;
  }
</style>
<template>
  <div>
	<div class="sccContainer" id="sccContainer">
		<h4>{{ city ? 'Cost Curve' : 'Select a city...' }}</h4>
		<a class="btn btn-sm btn-outline-secondary" @click="saveSVG">Save (SVG)</a>&nbsp;
		<a class="btn btn-sm btn-outline-secondary" @click="savePNG">Save (PNG)</a>&nbsp;
		<a class="btn btn-sm btn-outline-secondary" @click="refresh">Redraw</a>
		<div class="chartArea" id="scc">
		</div>
	</div>
  </div>
</template>

<script>
import _ from 'lodash';
import * as d3 from 'd3'
// import { drag } from 'd3-drag'
// import { select } from 'd3-selection'
// import transition from 'd3-transition'

export default {
  data () {
    return {
        pulseDraw: undefined
    }
  },
	props: [
            'oCity'
        ],
  computed: {
    city () {
		//return this.$store.getters.getActiveCity;
		return this.oCity;
    },
    projects () {
      return this.$store.getters.getProjects;
	},
	projectsReady () {
		return this.projects.length > 0;
	}
  },
   watch: {
		oCity (to, from) {
			if(to.id !== from.id) {
				setTimeout(() => this.draw(), 500); // hackish -- want to redraw after data updates (city, projects) -- 500ms should be OK
			}
		}
	},
  methods: {
    draw () {
		if(this.projects.length == 0) return;

		if(d3.select('#sccChart') != null){
			d3.select('#sccChart').remove();
		} 

		this.projects.sort(function(a,b){
			if(a.height < b.height) return -1;
			if(a.height > b.height) return 1;
			return 0;
		});
		var chartProjects = this.projects;
		var cityName = this.city;		

		var width = 600;
		var height = 500;
		var paddingX = 50; // each side
		var paddingY = 100; // each side
		// var paddingYtop = paddingY/2;
		// var paddingYbot = paddingY/2;
		var yBottom = height - paddingY;
		var totalX = 0;
		var maxY = 0;
		var minY = 0;
		
		var colorsBorder = ['red', 'blue', 'green', 'orange'];
		var colorsFill = ['salmon', 'skyblue', 'lightgreen', 'yellow'];
		
		//determine max/min projects
		for(var i = 0; i < chartProjects.length; i++){
			totalX += chartProjects[i].width;
			if (chartProjects[i].height > maxY) maxY = chartProjects[i].height;
			if (chartProjects[i].height < minY) minY = chartProjects[i].height;
		}
		
		var scaleX = (width - paddingX * 2) / totalX;
		// console.log('scaleX: ' + scaleX)
		// adjust the 'zero' x-axis bar position

		var zeroBar = 0;
		var scaleY = 0;

		if(minY < 0) {
			scaleY = (height - paddingY * 2) / (maxY - minY);
			zeroBar = (height - paddingY) + (minY * scaleY);
		} else {
			scaleY = (height - paddingY * 2) / (maxY);
			zeroBar = (height - paddingY);
		}

		
		var deb = _.debounce(init, 10);
		
		var svg = d3.select("#scc")
					.append("svg")
					.attr('id', 'sccChart')
					.attr('width', width)
					.attr('height', height)
					;
					
		var svgRect = svg.node().getBoundingClientRect();
		
		// add 10 so labels arent right on borders
		var freeBounds = {
			'x': svgRect.x + paddingX + 10,
			'y': svgRect.y + paddingY + 10,
			'width': svgRect.width - (paddingX * 2) - 10,
			'height': svgRect.height - zeroBar
		}
		
		// function drawBounds() {
		// 	d3.select(".bounds").remove();
		// 	svg.append("rect")
		// 			.attr("id", "bounds")
		// 			.attr("fill", "blue")
		// 			.attr("stroke", "black")
		// 			.attr("x", freeBounds.x)
		// 			.attr("y", freeBounds.y)
		// 			.attr("width", freeBounds.width)
		// 			.attr("height", freeBounds.height);
		// }
		
							

		// change color on mouseover
		function handleMouseOver(event, d, i) {
			// console.log('mouse over: #' + d.barID);
			d3.select('#' + d.barID).attr("fill", "white");
			d3.select("#" + d.barID).attr("fill-opacity", "1");
			d3.select("#" + d.barID).attr("stroke", "black");
			d3.select("#" + d.barID).attr("stroke-width", "2");
			
			d3.select("#text" + i).attr("font-weight", "bold");
			d3.select("#text" + i).attr("font-style", "italic");
		}
		
		// reset color on mouse out
		function handleMouseOut(event, d, i) {
			// console.log('mouse out: ' + d.barID);
			d3.select("#" + d.barID).attr("fill", function(d) { return d.fill; });
			d3.select("#" + d.barID).attr("fill-opacity", "0.05");
			d3.select("#" + d.barID).attr("stroke", function(d) { return d.stroke; });
			d3.select("#" + d.barID).attr("stroke-width", "1");
			
			d3.select("#text" + i).attr("font-weight", "normal");
			d3.select("#text" + i).attr("font-style", "normal");
		}
		
		var drag = d3.drag()
			// .subject(function (event, d) {
			// 	var o = d3.select('#sccChart');
			// 	return {x: o.attr('x'), y: o.attr('y')};
			// })
			.on("drag", function(event, d) {
				if(d.rotated || !d.drawLine){
					return;
				} 

				// console.dir(event)

				d3.select('#text' + d.clickIndex).attr('transform',null); // remove any transform since we want to set the absolute x,y
				var coords = [0,0];
				// coords = d3.pointer(d3.select('svg').node());
				coords = d3.pointer(event,d3.select('#sccChart').node());
				// console.log("coord[0]: " + coords[0] + ", coord[1]: " + coords[1])
				var newX = coords[0];
				var newY = coords[1];

				// var newX = event.x;
				// var newY = event.y;

				var xOff = 0;
				var yOff = 0;

				// xOff += document.getElementById('sccChart').getBoundingClientRect().x / 2;
				// yOff += document.getElementById('sccChart').getBoundingClientRect().y / 2;

				newX += xOff;
				newY += yOff;
				
				d3.select('#text' + d.clickIndex).attr("x", function(){
					return  newX;
				});
				d3.select('#text' + d.clickIndex).attr("y", function(){
					return newY;
				});
				
				d.bounds = this.getBoundingClientRect(); // re calc bounds

				deb(); // debounced re-draw function - wait 100ms after moving to draw lines again

			});

		
		function init(first = false) {
			
			if(svg){
				svg.selectAll('.rect').remove();
				svg.selectAll('.line').remove();
				//svg.selectAll('.text').remove();
				svgRect = svg.node().getBoundingClientRect();
			}
			
			if(first) {
				drawGrid(svg, svgRect);
				drawBars(svg, svgRect);
				drawTitles(svg, svgRect);
				drawScales(svg, svgRect);
			}
			//drawBounds();
			//drawTitles(svg, svgRect);
			drawLines(svg, svgRect);
			drawBorders(svg, svgRect);
		}
		
		function drawGrid(svg){
			// re-use axis code to get where the ticks should be
			d3.scaleLinear()
							.range([paddingX,(width-paddingX)])
							.domain([0.0,(0.0 + totalX)]);
		
			var yAxisScale = d3.scaleLinear()	
							.range([paddingY,yBottom])
							.domain([(maxY + 0.0),(minY + 0.0)]);
							
			// var xVals = xAxisScale.ticks();
			
			// start at first non-zero x-axis tick
//			for(var i = 1; i < xVals.length; i++){
//				svg.append('line')
//					.attr('stroke', '#d9d9d9')
//					.attr('stroke-width', '1px')
//					.attr("shape-rendering", "geometricPrecision") // crispEdges, optimizeSpeed, geometricPrecision, auto
//					.attr('x1', (paddingX + (xVals[i] * scaleX)))
//					.attr('x2', (paddingX + (xVals[i] * scaleX)))
//					.attr('y1', paddingY)
//					.attr('y2', (height - paddingY))
//			}
			
			var yVals = yAxisScale.ticks();
			for(var i = 0; i < yVals.length; i++){
				svg.append('line')
					.attr('stroke', '#eee')
					.attr('stroke-width', '1px')
					.attr("shape-rendering", "crispEdges") // crispEdges, optimizeSpeed, geometricPrecision, auto
					.attr('x1', paddingX)
					.attr('x2', (width - paddingX))
					.attr('y1', (zeroBar - (yVals[i] * scaleY)))
					.attr('y2', (zeroBar - (yVals[i] * scaleY)))
			}
		}
		
		// text borders for the line connecting to the bar (top or bottom of text)
		function drawBorders(svg, svgRect) {
			//console.log('drawing borders');
			var borders = svg.selectAll(".rect");

			borders.data(chartProjects)
				.enter()
				.append("rect")
				.attr("class", "rect")
				.attr("fill", "none")
				.attr("stroke", function(){
					return "black";
				})
				.attr("stroke-width", function(d) {
					if(d.drawLine) {
						return 1;
					} else {
						return 0;
					}
				})
				.style('stroke-dasharray', function(d) {
				
					// text is BELOW the top of bar
					if(d.lineXpos === 'left' && d.lineYpos === 'top') {
						return d.bounds.width + ", " + (d.bounds.height * 2 + d.bounds.width);
					}
					if(d.lineXpos === 'right' && d.lineYpos === 'top') {
						return d.bounds.width + ", " + (d.bounds.height * 2 + d.bounds.width);
					}
					
					
					// text is ABOVE top of bar
					if(d.lineXpos === 'left' && d.lineYpos === 'bottom') {
						return "0, " + (d.bounds.width + d.bounds.height) + ", " + d.bounds.width + ", " + d.bounds.height;
					}
					
					if(d.lineXpos === 'right' && d.lineYpos === 'bottom') {
						return "0, " + (d.bounds.width + d.bounds.height) + ", " + d.bounds.width + ", " + d.bounds.height;
					}
				})
				.attr("x", function(d) { 
					//return d.bounds.x - d.textWidth;
					return d.bounds.left - svgRect.left;

				})
				.attr("y", function(d) { 
					return d.bounds.top - svgRect.top;

				})
				.attr("width", function(d) {
					return d.bounds.width;

				})
				.attr("height", function(d) { 
					return d.bounds.height;

				});
			}
		
		function drawLines(svg, svgRect){
			//console.log('draw lines');
			var lines = svg.selectAll(".line");

			lines.data(chartProjects)
				.enter()
				.append("line")
				.attr("class", "line")
				.each(function(d, i) {
					d.bounds = d3.select('#text' + i).node().getBoundingClientRect();
					var leftOfText = d.bounds.left - svgRect.left;
					var rightOfText = (d.bounds.left - svgRect.left) + d.bounds.width;
					
					var topOfText = d.bounds.top - svgRect.top;
					var bottomOfText = d.bounds.top - svgRect.top + d.textHeight;

					if(leftOfText + d.textWidth > d.xPos) {
						d.lineX = leftOfText;
						d.lineXpos = 'left';
					} else {
						d.lineX = rightOfText;
						d.lineXpos = 'right';
					}
					
					if(topOfText > d.yPos) {
						//console.log('line should be from top')
						d.lineY = d.bounds.top - svgRect.top;
						d.lineYpos = 'top';
					} else {
						d.lineY = bottomOfText;
						d.lineYpos = 'bottom';
						//console.log('line should be from bottom')
					}
					
				})
				.style("stroke", function() {
					return "black";
					//return d.lineColor;
				})
				.attr("shape-rendering", "geometricPrecision") // crispEdges, optimizeSpeed, geometricPrecision, auto
				.attr("stroke-width", function(d) {
					if(d.drawLine) {
						return 1;
					} else {
						return 0;
					}
				})
				.attr("x1", function(d){
					return d.lineX;
					//return d.bounds.left - svgRect.left;

				})
				.attr("x2", function(d){
					return d.xPos; // x pos of bar
				})
				.attr("y1", function(d){
					return d.lineY;
					//return d.yVal; // y pos of text
				})
				.attr("y2", function(d){
					return d.yPos; // y pos of bar
				})
				.on("mouseover", handleMouseOver)
				.on("mouseout", handleMouseOut);
			//drawBorders();
		}
		
		function drawBars(svg) {
			var bars = svg.selectAll(".bar");
			var cumulX = 0;
			bars.data(chartProjects)
				.enter()
				.append("rect")
				.attr('id', function(d, i) {
					return 'bar-' + i;
				})
				.attr("fill", function(d, i) { d.fill = colorsFill[i %colorsFill.length]; return d.fill;})
				.attr("fill-opacity", "0.05")
				.attr("stroke", function(d, i) { d.stroke = colorsBorder[i % colorsBorder.length]; return d.stroke;})
				.attr("height", function(d) { return Math.abs(d.height) * scaleY })
				.attr("width", function(d) { return d.width * scaleX })
				.attr("x", function(d) { 
					cumulX += (d.width * scaleX);
					d.xPos = paddingX + (cumulX - (d.width * scaleX));
					// if(d.xPos === undefined) d.xPos = 0;
					return d.xPos;
				})
				.attr("y", function(d) { 
					if(d.height >= 0) {
						// make the top above the 'zero' bar by the scaled height
						d.yPos = (zeroBar) - (d.height * scaleY);
						return d.yPos;
					} else {
						// make top equal to 'zero' bar so it extends below
						d.yPos = zeroBar;
						return d.yPos;
					}
				})
				.each(function(d, i) {
					d.barID = 'bar-' + i;
					d.clickIndex = i;
					
				})
				.on("mouseover", handleMouseOver)
				.on("mouseout", handleMouseOut);
		}
		
		function drawTitles(svg, svgRect) {
			var titles = svg.selectAll(".text");
			titles.data(chartProjects)
				.enter()
				.append("text")
				.text(function(d) { return d.name })
				.attr("class", "text")
				.attr('id', function(d,i) { return 'text' + i })
				.attr("fill", "black")
				.style('cursor', 'pointer')
				.attr('font-size', '12px')
				.style("text-anchor", "middle")
				//.style("pointer-events", "none")
				.attr("x", function(d) {
					d.xVal = d.xPos + ((d.width * scaleX) / 2);
					//console.log('setting x on title: ' + d.label + ' to: ' + d.xVal);
					return d.xVal; 
				})
				.attr("y", function(d) { 
					if(d.height >= 0) {
						// make the top above the 'zero' bar by the scaled height
						d.yVal = (zeroBar) - (d.height * scaleY / 2) ;
						return (zeroBar) - (d.height * scaleY / 2) ;
					} else {
						// make top equal to 'zero' bar so it extends below
						d.yVal = (zeroBar - (d.height * scaleY / 2));
						return (zeroBar - (d.height * scaleY / 2));
					}
				})
				.each(function(d) {
					d.rotated = false; // init to unrotated
					// d.id = "text" + i;
					d.bounds = this.getBoundingClientRect();
					d.textHeight = d.bounds.height;
					d.textWidth = d.bounds.width;
				})
				//.transition()
				//.duration(2000)
				.attr("transform", function(d) {

					// we want to compare the bounds of the label to the boundaries of the box it is for
					//  1 & 2) if the label is smaller than the box, then place it within either horizontally (pref)
					// or vertically
					//  3) if the label can fit vertically within the width of the box, but not the height
					// then place it vertically ABOVE the box
					//  4) else, we need to place the label in whitespace, which we need to find somehow
					
					var padding = 5; // padding around label within box
					
					var fitX = (d.bounds.width  + (padding*2)) < (d.width * scaleX); // fit horiz. within x
					var fitY = (d.bounds.height + (padding*2)) < (d.height * scaleY);// fit horiz. within y
					
					var fitRotX = (d.bounds.height + (padding*1)) < (d.width * scaleX); // fits rotated within x
					var fitRotY = (d.bounds.width + (padding*2)) < (d.height * scaleY); // fits rotated within y 
					
					// fit rotated above bar (enough vertical space under heading)
					var fitAboveBar = (zeroBar - ((d.height * scaleY) + d.bounds.width) > paddingY);

					// x and y base give the center point of the text relative to the SVG area
					// which we need to rotate the text on the spot
					var xBase = (d.bounds.x - svgRect.x) + d.bounds.width/2;
					var yBase = (d.bounds.y - svgRect.y) + d.bounds.height/2;
					
					// the rotated bottem is (yBase + d.bounds.width/2)
					
					// distance from center of text to the top of the bar
					var yBarDist = 0;

					if(d.height > 0){
						//bar is above zero bar
						yBarDist = ((yBase + d.bounds.width/2) - zeroBar) + padding + (d.height * scaleY);
					} else {
						// bar is below zerobar
						yBarDist = ((yBase + d.bounds.width/2) - zeroBar) + padding;
					}
					
					
					// 1 fit horz. within bounds
					if(fitX && fitY){
						// fits in x and y - no translation needed
						d.drawLine = false;
						
						//d3.select(this).style("pointer-events", "none");
						return "translate(0,0)"; 
					}
					// 2 fit vert. within bounds
					else if(fitRotX && fitRotY){
						//console.log('fit rotated in x and y: ' + d.label);

						d.rotated = true;
						d.drawLine = false;
						//d3.select(this).style("pointer-events", "none");
						return "rotate(-90 " + xBase + " " + yBase + ")";
					}
					// 3 fit vert above box
					else if((fitRotX && !fitRotY) && fitAboveBar){
						
						d.rotated = true;
						d.drawLine = false;
						return "rotate(-90 " + xBase + " " + yBase + ") translate(" + yBarDist + ",0)";

					} else {
						// draw title at bottom of freeBounds, move bottom up by height 
						
						// 1 move from CURRENT X to FREEBOUNDS X
						// 2 move from CURRENT Y to FREEBOUNDS Y + HEIGHT
						// 3 adjust FREEBOUNDS height by CURRENT BOUNDS HEIGHT
						
						//1, 2
						// console.log('move from curr x: ' + d.bounds.x + ' to free x: ' + freeBounds.x);
						// console.log('label: ' + d.label);
						var xDiff =  (freeBounds.x - d.bounds.x);
						var yDiff =  ((freeBounds.y + freeBounds.height) - d.bounds.y) - d.bounds.height;
						//3
						freeBounds.height -= d.bounds.height;
						if(freeBounds.height < 0) freeBounds.height = 0;
						
						//since we are not drawing within or above the box, we need a line connecting to the box
						d.drawLine = true;
						//return "translate(0,0)";
						return "translate(" + (xDiff) + "," + (yDiff) + ")";
					}
				})
				.each(function(d) {
					//recalc bounds after above translation
					d.bounds = this.getBoundingClientRect();

					d.textHeight = d.bounds.height;
					d.textWidth = d.bounds.width;

				})
				.on("mouseover", handleMouseOver)
				.on("mouseout", handleMouseOut)
				.call(drag)
				;
		}
		
		function drawScales(svg) {
			var xAxisScale = d3.scaleLinear()
							.range([paddingX,(width-paddingX)])
							.domain([0.0,(0.0 + totalX)]);
		
			var yAxisScale = d3.scaleLinear()	
							.range([paddingY,yBottom])
							.domain([(maxY + 0.0),(minY + 0.0)]);
							
			var xAxis = d3.axisBottom(xAxisScale);
			svg.append("g")
							//.attr("transform", "translate(" + 0 + "," + (height - paddingY) + ")")
							.attr("transform", "translate(" + 0 + "," + zeroBar + ")")
							.call(xAxis);
		
			var yAxis = d3.axisLeft(yAxisScale);
			svg.append("g")
							.attr("transform", "translate(" + paddingX + "," + 0 + ")")
							.call(yAxis);
							
			// draw title
			//
			svg.append('text')
				.attr('x', (width / 2))
				.attr('y', paddingY/2)
				.attr('font-weight', 'bold')
				.attr('font-size', '20px')
				.attr('text-anchor', 'middle')
				.text('Sustainability Cost Curve');
			svg.append('text')
				.attr('x', (width / 2))
				.attr('y', paddingY/2)
				.attr('dy', '1.2em')
				.attr('font-size', '12px')
				.attr('text-anchor', 'middle')
				.text(cityName.name + ', ' + cityName.country);
				
			// draw Y axis title - line 1
			svg.append('text')
				.attr('y', 0)
				.attr('x', 0)
				.attr('transform', 'translate(' + (paddingX/4) + ',' + (height/2) + ') rotate(-90)')
				.attr('font-weight', 'bold')
				.attr('text-anchor', 'middle')
				.text('Sustainability Cost');

			// draw Y axis title - line 2
			svg.append('text')
				.attr('y', 0)
				.attr('x', 0)
				// .attr('dx', '1em')
				.attr('dy', '1em')
				.attr('transform', 'translate(' + (paddingX/4) + ',' + (height/2) + ') rotate(-90)')
				.attr('font-weight', 'normal')
				.attr('text-anchor', 'middle')
				.attr('font-size', '0.8em')
				.text('($ Bn / unit of Sustainability)');

			// draw X axis title
			svg.append('text')
				.attr('x', (width / 2))
				.attr('y', height - paddingY/2)
				.attr('font-weight', 'bold')
				.attr('text-anchor', 'middle')
				.text('Sustainability Potential per Year');
			
		}


		init(true); // true for first init

    },
    refresh () {
        this.draw();
    },
		saveSVG () {
				var cityName = this.city;
				const svg = document.getElementById('scc').innerHTML;
				const blob = new Blob([svg.toString()]);
				const element = document.createElement("a");
				element.download = "Cost Curve for " + cityName.name.trim() + " " + cityName.country.trim() + ".svg"; 
				element.href = window.URL.createObjectURL(blob);
				element.click();
				element.remove();
		},
    savePNG () {
				var cityName = this.city;
        var doctype = '<?xml version="1.0" standalone="no"?>'
        + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
        var source = (new XMLSerializer()).serializeToString(d3.select('svg').node());
        var blob = new Blob([ doctype + source], { type: 'image/svg+xml;charset=utf-8' }); 
        var url = window.URL.createObjectURL(blob);
        var img = d3.select('body').append('img')
            .attr('width', 600)
            .attr('height', 500)
            .attr('class', 'tempIMG')
            .node();

				img.onload = function(){
            // Now that the image has loaded, put the image into a canvas element.
            var canvas = d3.select('body').append('canvas').attr('class', 'tempIMG').node();
            canvas.width = 600;
            canvas.height = 500;
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0);
            var canvasUrl = canvas.toDataURL("image/png");
						// this is now the base64 encoded version of our PNG! you could optionally 
						// redirect the user to download the PNG by sending them to the url with 
						// `window.location.href= canvasUrl`.
            var a = document.createElement('a');
            a.href = canvasUrl;
            a.download = "Cost Curve for " + cityName.name.trim() + " " + cityName.country.trim() + ".png"; 
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            d3.select('.tempIMG').remove();
            canvas.remove();
     }
		// start loading the image.
    img.src = url;
    }	
  },
  //watch: {
  //    projects: function (n, o) {
  //      console.log('projects changed');
  //	
  //      this.draw();
  //    }
  //},
  created() {
    window.addEventListener('resize', this.draw);
    window.addEventListener('scroll', this.handleScroll);
//    this.pulseDraw = setInterval(this.draw, 4000);
  },
  destroyed() {
    window.removeEventListener('resize', this.draw);
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.pulseDraw);
  },
  mounted() {
   // console.log('scc mounted');
    this.draw();

  }
}
</script>

<style scoped>
.scc {
	position: relative;
	margin: auto;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.tempIMG {
	visibility: hidden;
	display: none;
}

.chartArea {
	width: 80%;
	margin: 0 auto;
}

/*
#scc {
	position: absolute;
}
*/
.sccContainer {
/*	position: fixed;*/
	text-align: center;
/*	width: 50%;
	height: 100%;*/
}
</style>
<template>
    <div class="home-page-content">
        <div class="section">
            <h4>Welcome to the sustainability assessment project.</h4>
            <br>

            <div>
                <strong>To go directly to a city assessment, click on the ‘Search’ tab, type in name of city you are interested in (or use map to select city).</strong>
            </div>
            <br>
            
            <div>
                This site provides ‘sustainability assessments’ for the world’s cities – those urban regions with more than 5 million residents. Consistent with the World Urbanization Prospects, United Nations, the overall urban system (city region) is assessed. The list of cities and populations projected to 2100 are available at <a style="color:darkblue;" href='http://journals.sagepub.com/doi/abs/10.1177/0956247816663557'>Hoornweg and Pope, 2016.</a>
            </div>
            <br>
            
            <div>
                Sustainability is measured under two broad areas; bio-physical and socio-economic. Tables 1 and 2 list aspirational values for each metric, derived from locally-relevant planetary boundaries and global Sustainable Development Goals (SDG) Ambio report.
            </div>
            <br>
            
            <div>
                <strong>Bio-physical indicators</strong> are derived mainly from planetary boundaries developed by Rockstrom (2009) and updated by Steffen et al. (2015). See figure. <br>
                
                <div class="text-center">
                  <img width="500px" src='../assets/planetary_boundries.png' />
                </div>
                <div class="text-center">
                  <small>The nine planetary boundaries, as visualized by the Stockholm Resilience Centre/Globaïa.
                  Steffen et al. (2015) Science.</small>
                </div>
            </div>
            <br>
        </div>
            
            <div class="section softRoundedBorder">
            To assess bio-physical boundaries from the perspective of cities, the following indicators are used:<br>
                <ul>
                  <li><strong><i>Climate change</i></strong> – GHG emissions</li>
                  <li><strong><i>Biodiversity loss</i></strong> – ecological footprint, index of biodiversity impact</li>
                  <li><strong><i>Fresh water use</i></strong> – per capita water consumption, potable water supply, index of embodied water consumption</li>
                  <li><strong><i>Change in land use</i></strong> – local land use change, population density, Index of global land use</li>
                  <li><strong><i>Nitrogen cycle</i></strong> – estimated per capita consumption patterns</li>
                  <li><strong><i>Chemical pollution</i></strong> – solid waste collection, wastewater collection, PM 10, PM 2.5, Index of solid waste (under development)</li>
                  <li><strong><i>Urban resilience</i></strong> – number of natural disaster related deaths, annual GDP, threated GDP, GDP loss due to natural disasters, Index of resilience</li>
                </ul>
            </div>
            
            <div class="section softRoundedBorder">
                <strong>Socio-economic indicators</strong> are derived mainly from the Sustainable Development Goals as proposed through the United Nations, 2015. See Figure.<br>
            
                <div class="text-center">
                  <img src='../assets/s-e_indicators.png' />
                </div>
            </div>
            
            <div class="section softRoundedBorder">
                To assess socio-economic indicators from the perspective of cities, the following indicators are used:<br>
                <ul>
                  <li><strong><i>Youth opportunity</i></strong> – under 5 mortality, gender equity, females in schools, youth unemployment, life expectancy</li>
                  <li><strong><i>Economy</i></strong> – unemployment rate, Gini coefficient, population living in slums/homeless, GDP</li>
                  <li><strong><i>Energy access/intensity</i></strong> – authorized electrical service, clean energy for cooking, energy intensity</li>
                  <li><strong><i>Mobility and Connectivity</i></strong> – number of automobiles, daily public transport trips, internet connections, mode of travel, transportation fatalities, commercial air connectivity</li>
                  <li><strong><i>Institutions</i></strong> – ease of doing business, corruption of city officials, ratio of tax collected, debt service ratio</li>
                  <li><strong><i>Basic services</i></strong> – solid waste collection, wastewater collection, potable water supply</li>
                  <li><strong><i>Security and public safety</i></strong> – fire related deaths, homicides, violent crime rate, Index of health care.</li>
                </ul>
            </div>
            
            <div class="section softRoundedBorder">
                <u>A note on Estimates and Confidence</u> <br><br>
                
                As outlined in a Cities and Sustainability: A new approach, global targets (at city-level) are available for all indicators. These are provided in the ‘Target’ column for each city. For each estimated value, references are provided (see below). A ‘Confidence’ level is also provided representing the degree of confidence associated with the estimate. Generally One star represents a general estimate, often down-scaled national estimates. Two stars represent referenced estimates specific to the city. Three stars denote peer reviewed information specific to the city. Four stars represent peer reviewed, and time series data. Five stars would represent peer reviewed, third party audited data representing the entire urban area. World Council on City Data would represent this standard, however currently there are no complete urban agglomerations where all local governments are reporting city metrics, subject to third party auditing.
            </div>
            <br>
            
            <div class="section softRoundedBorder">
                Efforts are underway to improve data availability, refine indicators, and expand the list of reported cities. As SDGs and planetary boundaries are updated, city-based targets will be updated as well.
            </div>
            
            
            
                
            <div class="section softRoundedBorder">
                <br>
                <div class="text-center">
                    Table 1: Bio-physical indicators, global current average<br>    
                </div>
                
                <table>
                    <tr>
                        <th>Physical Science Indicator</th>
                        <th>Unit</th>
                        <th>Global (Current)</th>
                        <th>Global (Target/Limits)</th>
                        <th>Source</th>
                    </tr>
                    <tr class="highlightRow">
                        <th colspan="5">Carbon Dioxide Emission</th>
                    </tr>
                    <tr>
                        <td>GHG emissions per capita</td>
                        <td>(tCO<sub>2</sub>/cap. year)</td>
                        <td>4.71</td>
                        <td>2</td>
                        <td>Adapted from Comm on Cl. Change</td>
                    </tr>
                    <tr>
                        <td>GHG emissions per capita</td>
                        <td>(tCO<sub>2</sub>/cap. year)</td>
                        <td>4.71</td>
                        <td>2</td>
                        <td>Adapted from Comm on Cl. Change</td>
                    </tr>
                    <tr>
                        <td>National Share</td>
                        <td>x</td>
                        <td>x</td>
                        <td>x</td>
                        <td>zzz</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Rate of Biodiversity Loss</th>
                    </tr>
                    <tr>
                        <td>Ecological footprint</td>
                        <td>Global hectares demanded per capita</td>
                        <td>2.6</td>
                        <td>1.7</td>
                        <td>WWF Living Planet Report (down-scaled national values)</td>
                    </tr>
                    <tr>
                        <td>Index of biodiversity impact</td>
                        <td>Low - Very High</td>
                        <td>Very High</td>
                        <td>Low</td>
                        <td>Estimated</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Fresh Water Use</th>
                    </tr>
                    <tr>
                        <td>Total per capita water consumption<sup>1</sup></td>
                        <td>L/cap/day</td>
                        <td>1148</td>
                        <td>1546</td>
                        <td>Rockstrom <i>et al.</i> (2009)</td>
                    </tr>
                    <tr>
                        <td style="text-align: right;"><i>updated value</i></td>
                        <td>L/cap/day</td>
                        <td>989</td>
                        <td>1522</td>
                        <td>Steffen <i>et al.</i> (2015)</td>
                    </tr>
                    <tr>
                        <td>Percent of city with potable water supply</td>
                        <td>%</td>
                        <td>81</td>
                        <td>95</td>
                        <td>Adapted from WHO, estimated value</td>
                    </tr>
                    <tr>
                        <td>Index of embodied water consumption (Litres)</td>
                        <td>Low - Very High</td>
                        <td>Low</td>
                        <td>Low</td>
                        <td>Estimated</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Change in Land Use</th>
                    </tr>
                    <tr>
                        <td>Local land use change (Ha)</td>
                        <td>% of land converted for cropland</td>
                        <td>11.7</td>
                        <td>15</td>
                        <td>Rockstrom <i>et al.</i> (2009)</td>
                    </tr>
                    <tr>
                        <td style="text-align:right;"><i>updated value</i></td>
                        <td>Area of forested land as % of original forest cover</td>
                        <td>65</td>
                        <td>75</td>
                        <td>Steffen <i>et al.</i> (2015)</td>
                    </tr>
                    <tr>
                        <td>Population density</td>
                        <td>person/km<sup>2</sup></td>
                        <td>3500</td>
                        <td>TBD</td>
                        <td>Demographia 2016</td>
                    </tr>
                    <tr>
                        <td>Index of global land use impact (Ha)</td>
                        <td>Low - Very High</td>
                        <td>Low</td>
                        <td>Low</td>
                        <td>Estimated</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Nitrogen Cycle</th>
                    </tr>
                    <tr>
                        <td>Per capita value as percent of global values based on estimated consumption patterns</td>
                        <td>kg-N<sub>2</sub>/cap/year</td>
                        <td>18</td>
                        <td>5.5</td>
                        <td>Rockstrom <i>et al.</i> (2009)</td>
                    </tr>
                    <tr>
                        <td style="text-align:right;"><i>updated value</i></td>
                        <td>kg-N<sub>2</sub>/cap/year</td>
                        <td>21</td>
                        <td>9</td>
                        <td>Steffen <i>et al.</i> (2015)</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Pollution</th>
                    </tr>
                    <tr>
                        <td>Percentage of city population with regular solid waste collection</td>
                        <td>%</td>
                        <td>50</td>
                        <td>80</td>
                        <td>Municipality Waste Management, estimated data</td>
                    </tr>
                    <tr>
                        <td>Percentage of city population served by wastewater collection</td>
                        <td>%</td>
                        <td>76</td>
                        <td>80</td>
                        <td><a href="http://www.worldwaterweek.org">World Water Week</a>, estimated data</td>
                    </tr>
                    <tr>
                        <td>PM 2.5; PM 10; O3</td>
                        <td>&#181;g/m<sup>3</sup></td>
                        <td>20</td>
                        <td>10</td>
                        <td>World Health Organization (WHO)</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Geophysical Risk</th>
                    </tr>
                    <tr>
                        <td>Number of natural disaster related deaths</td>
                        <td>per 100,000 population</td>
                        <td>0.134</td>
                        <td>0.09</td>
                        <td>Adapted from Guha-Sapir</td>
                    </tr>
                    <tr>
                        <td>Percentage of GDP loss due to natural disasters</td>
                        <td>%</td>
                        <td>0.2</td>
                        <td>0.1</td>
                        <td>b$ 143 in 2012/UCL-WHO</td>
                    </tr>
                    <tr>
                        <td>Resilience of city</td>
                        <td>Low - Very High</td>
                        <td>Medium</td>
                        <td>High</td>
                        <td>Estimated</td>
                    </tr>
                </table>
                
                <div style="text-align:left;">
                    <div style="max-width:80%;margin:0 auto;">
                      <small><sup>1</sup> This represents cumulative fresh water withdrawal from all sources. Cities usually report only their domestic water consumption; eventually embodied water consumption would be included, similar to GHG emissions inventories.</small>      
                    </div>
                </div>
                <br>
                
                <br>
                <div class="text-center">
                    Table 2: Global social indicators (current global average values compares to target/limit)<br>
                </div>
                <table>
                    <tr>
                        <th>Indicators</th>
                        <th>Unit</th>
                        <th>Current</th>
                        <th>Target</th>
                        <th>Comment/Source</th>
                    </tr>
                    <tr class="highlightRow">
                        <th colspan="5">Youth Opportunity</th>
                    </tr>
                    <tr>
                        <td>Under 5 mortality</td>
                        <td>deaths per 1000 live births</td>
                        <td>51</td>
                        <td>17</td>
                        <td>Development Goals (UN 2013, 2015)</td>
                    </tr>
                    <tr>
                        <td>Gender equity</td>
                        <td></td>
                        <td>0.66</td>
                        <td>1</td>
                        <td>Development Goals (UN 2013, 2015)</td>
                    </tr>
                    <tr>
                        <td>Percentage of females in schools</td>
                        <td>%</td>
                        <td>85</td>
                        <td>95</td>
                        <td>494.9m illiterate women in 2007 (UN)</td>
                    </tr>
                    <tr>
                        <td>Youth unemployment rate</td>
                        <td>%</td>
                        <td>12.4</td>
                        <td>12.8</td>
                        <td>2012 rate and 2018 estimate by UN-ILO</td>
                    </tr>
                    <tr>
                        <td>Average life expectency</td>
                        <td>years</td>
                        <td>70</td>
                        <td>70</td>
                        <td>70 is 2015 target (UN)</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Economy</th>
                    </tr>
                    <tr>
                        <td>Unemployment rage</td>
                        <td>%</td>
                        <td>6</td>
                        <td>6*</td>
                        <td>ILO-UN, 2013, *Estimated</td>
                    </tr>
                    <tr>
                        <td>Gini Coefficient</td>
                        <td></td>
                        <td>0.52</td>
                        <td>0.2*</td>
                        <td>The Conference Board of Canada, *Estimated</td>
                    </tr>
                    <tr>
                        <td>Percentage of population living in slums</td>
                        <td>%</td>
                        <td>25</td>
                        <td>18</td>
                        <td>Development Goals (UN 2013, 2015)</td>
                    </tr>
                    <tr>
                        <td>GDP</td>
                        <td>$/cap</td>
                        <td>10,496</td>
                        <td>20,000*</td>
                        <td>b$74910, 7.13 million pop, 2013 (WB), *Estimated</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Energy Access and Intensity</th>
                    </tr>
                    <tr>
                        <td>Percentage of city with authorized electrical service</td>
                        <td>%</td>
                        <td>94</td>
                        <td>100</td>
                        <td>0.21 million urban residents w/o access (IEA)</td>
                    </tr>
                    <tr>
                        <td>Percentage of city with access to clean energy for cooking</td>
                        <td>%</td>
                        <td>88</td>
                        <td>100</td>
                        <td>0.43 million urban residents w/o access (IEA)</td>
                    </tr>
                    <tr>
                        <td>Energy Intensity</td>
                        <td>MJ/$</td>
                        <td>8.9</td>
                        <td>8.9</td>
                        <td>Wikipedia: List of countries by energy intensity, 2003</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Mobility and Connectivity</th>
                    </tr>
                    <tr>
                        <td>Number of personal automobiles per capita</td>
                        <td>vehicle/cap</td>
                        <td>0.15</td>
                        <td>0.2</td>
                        <td>1.02 billion in 2010, 1.58 billion in 2020</td>
                    </tr>
                    <tr>
                        <td>Daily number of public transport trips per capita</td>
                        <td>trips/cap/day</td>
                        <td>0.35</td>
                        <td>0.35*</td>
                        <td>*Estimated</td>
                    </tr>
                    <tr>
                        <td>Number of internet connections</td>
                        <td>% population</td>
                        <td>40</td>
                        <td>50</td>
                        <td>Internet Live Stats</td>
                    </tr>
                    <tr>
                        <td>Percentage of commuters using a travel mode other than a personal vehicle to work</td>
                        <td>%</td>
                        <td>30</td>
                        <td>50*</td>
                        <td>* Estimated</td>
                    </tr>
                    <tr>
                        <td>Transportation fatalities</td>
                        <td>per 100,000 population</td>
                        <td>17.2</td>
                        <td>8.6</td>
                        <td>World Health Organization</td>
                    </tr>
                    <tr>
                        <td>Commercial air connectivity</td>
                        <td># of destinations</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Institutions</th>
                    </tr>
                    <tr>
                        <td>Ease of doing business - World Bank (downscaled from country to city level)</td>
                        <td></td>
                        <td>95</td>
                        <td>95</td>
                        <td>International Finance Corporation</td>
                    </tr>
                    <tr>
                        <td>Number of convictions for corruption by city officials</td>
                        <td>per 100,000 population</td>
                        <td>42.7</td>
                        <td>50</td>
                        <td>Index Average/Transparency International</td>
                    </tr>
                    <tr>
                        <td>Tax collected as percent of tax billed</td>
                        <td>%</td>
                        <td></td>
                        <td></td>
                        <td>TBD from GCI (WCCD)</td>
                    </tr>
                    <tr>
                        <td>Debt service ratio</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>TBD from GCI (WCCD)</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Basic Services</th>
                    </tr>
                    <tr>
                        <td>Percentage of population with regular wolid waste collection</td>
                        <td>%</td>
                        <td>50</td>
                        <td>80*</td>
                        <td>Urban Solid Waste Management, *Estimated</td>
                    </tr>
                    <tr>
                        <td>Percentage of city population served by wastewater collection</td>
                        <td>%</td>
                        <td>76</td>
                        <td>80*</td>
                        <td>2008, Urban regaions, <a href="http://worldwaterweek.org">worldwaterweek.org</a>, * Estimated</td>
                    </tr>
                    <tr>
                        <td>Percentage of population served with potable water supply</td>
                        <td>%</td>
                        <td>81</td>
                        <td>95*</td>
                        <td>United Nations, *Estimated</td>
                    </tr>
                    
                    <tr class="highlightRow">
                        <th colspan="5">Security and Public Safety</th>
                    </tr>
                    <tr>
                        <td>Number of fire related deaths</td>
                        <td>per 100,000 population</td>
                        <td>3.6</td>
                        <td>0.5*</td>
                        <td>265,000 deaths/y (WHO), *Estimated</td>
                    </tr>
                    <tr>
                        <td>Number of homicides</td>
                        <td>per 100,000 population</td>
                        <td>6.1</td>
                        <td>3.05*</td>
                        <td>437,000 cases in 2013 (UNODC), *Estimated</td>
                    </tr>
                    <tr>
                        <td>Violent crime rate</td>
                        <td>per 100,000 population</td>
                        <td></td>
                        <td></td>
                        <td>TBD from GCI (WCCD)</td>
                    </tr>
                </table>
                
            </div>
    <app-footer position="relative" />
    </div>
</template>

<script>
    import Footer from './Footer.vue'
    
    export default {
        components: {
            'appFooter': Footer
        }
    }
</script>

<style scoped>
      
    .home-page-content {
        /*display: flex;*/
        /*padding: 10px;*/
        height: 100%;
        width: 100%;
    }

    .section {
        width: 100%;
        padding: 10px;
    }
    
    .highlightRow {
        background: lightgray;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
    }
    
    table {
        display: table;
        margin: 0 auto;
        font-size: 0.8em;
        border: 1px solid darkgray;
        max-width: 80%;
    }
    
    th, td {
        padding-left: 5px;
        padding-right: 5px;
    }
</style>

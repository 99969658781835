<template>
  <div id="app">
      <div class="text-center header">
        <h3>Sustainability Assessment</h3>
        <h6><i>Measuring Sustainable Development Across the World's Cities</i></h6>
        <router-link to="/">Home</router-link>&nbsp;
        <router-link to="/search"><a @click="setSearchType('city')">Cities</a></router-link>&nbsp;
        <!--<router-link to="/search"><a @click="setSearchType('region')">Regions</a></router-link>&nbsp;-->
        <router-link to="/ranks">Index Ranking</router-link>&nbsp;
        <router-link to="/contact">Contact</router-link>&nbsp;

        <div class="login-container" v-if="!$auth.loading">
        <!-- show who is logged in when authenticated -->
        <h4 v-if="$auth.isAuthenticated" style="position: relative; top:-20px; margin: -15px;">Welcome, {{ $auth.user.name }}!</h4>

        <!-- show login when not authenticated -->
        <a v-if="!$auth.isAuthenticated" @click="login" class="button is-dark">
          <b-button variant="outline-primary">Sign In</b-button>
        </a>
        <!-- show logout when authenticated -->
        <a v-if="$auth.isAuthenticated" @click="logout" class="button is-dark">
          <b-button variant="outline-primary">Log Out</b-button>
        </a>
      </div>

      </div>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
  </div>
</template>

<script>

export default {
  name: 'app',
  data () {
    return {
    }
  },
  computed: {
    quickLinks () {
      return this.$store.getters.getQuickLinks
    },
    ready () {
      return this.$store.getters.isReady
    }
  },
  methods: {
    setSearchType(type) {
      this.$store.commit('setSearchType',type)
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  created () {
    console.log('app created')
    this.$store.dispatch('init')
  },
  destroyed () {
    console.log('app destroyed')  
  }
}
</script>

<style>
html, body {
  margin:0;
  padding:0;
  height: 100%;
  max-height: 100%;
}

.login-container {
  position: absolute;
  top: 70px;
  right: 30px;
} 

#app {
  position:relative; /* needed for footer positioning*/
  margin:0 auto; /* center, not in IE5 */
  
  height:auto !important; /* real browsers */
  height:100%; /* IE6: treaded as min-height*/

  min-height:100%; /* real browsers */  
  /*max-height: 100%;*/
}

 .fixed-content {
      /* z-index: -1; */
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: calc(100% - 220px);
      /*position: fixed;*/
      /*top: 100px;*/
      margin-top: 120px;
      margin-bottom: 100px;
      overflow: auto;
  }

.container {
  margin: 0 !important;
  max-width: 100% !important;
}

.header {
  /* z-index: 200; */
  padding-top: 20px;
  padding-bottom: 20px;
  height: 120px;
  color: rgb(0,60,113);
  background: white;
  border-bottom: 1px solid darkslategray;
}

  .footer {
    /*position: absolute;*/
    /*bottom: 0;*/
    height: 100px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background: rgb(0,60,113);
    color: white;
    border-top: 1px solid darkslategray;
  }

.snug {
  padding: 0 !important;
  margin: 0 !important;
}

.tight {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.fill {
  min-height: 500px;
  max-height: 500px;
  height: 500px;
  width: 100%;
}

.link-like {
  cursor: pointer;
}


.btn-primary {
    background: rgb(0,60,113);
    color: white;
    border: 1px solid black;
}

/* router transition below */

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

/* end router transition */

/* Loading spinner below */

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}

/* end loading spinner */

</style>

import Vue from 'vue'
import Vuex from 'vuex'
require('es6-promise').polyfill();
import axios from 'axios'

Vue.use(Vuex)

axios.defaults.baseURL = 'https://api.city-sustainability.com'
// axios.defaults.baseURL = 'https://1ln30f6dx4.execute-api.us-east-1.amazonaws.com/dev'

export const store = new Vuex.Store({
    state: {
        // key: values
        cities: [],
        cityData: {}, // object where key is city id, so we can store city data without re-loading
        rockstromData: {}, // object with key = cityId and data is the draw-able rockstrom dat
        quickLinks: [],
        activeCity: undefined,
        template: [],
        queueCity: undefined,
        queueRock: undefined,
        rankedIndicators: [],
        projects: [],
        searchType: "city" // "city" or "region" depending on the link clicked to get to search page
    },
    getters: {
        // getVar () { return state.var }
        getCities (state) {
            return state.cities
        },
        getActiveCity (state) {
            return state.activeCity
        },
        getCityData (state) {
            return state.cityData
        },
        getProjects (state) {
            return state.projects
        },
        getSearchType (state) {
            return state.searchType  
        },
        getRockData (state) {
            return state.rockstromData  
        },
        getTemplate (state) {
            return state.template
        },
        getRankedIndicators (state) {
            return state.rankedIndicators  
        },
        getQuickLinks (state) {
            return state.quickLinks
        },
        isReady (state) {
            return state.template.length > 0 && state.cities.length > 0
        }
    },
    mutations: {
        // sync functions - mutations are called from commit
        // fn (state, payload) { state.var = payload }
        setCities(state, payload) {
            // for(var i = 0; i < payload.length; i++) {
            //     state.cities[i] = {
            //         'name': payload[i].name,
            //         'country': payload[i].country,
            //         'lat': payload[i].lat,
            //         'lng': payload[i].lng
            //     }
            // }
            
            state.cities = payload
            if(state.queueCity !== undefined) {
                // console.log('resolving city queue')
                
                for(var i = 0; i < state.cities.length; i++){
                    if(state.cities[i].id == state.queueCity) {
                        state.activeCity = state.cities[i]
                        break
                    }
                } 
                state.queueCity = undefined
            }
            
        },
        setActiveCity(state, payload) {
            state.activeCity = payload
        },
        setActiveCityById(state, payload) {
            // for the case when a page is routed to a city data page
            // instead of through a button click
            // the city must be found in the list of cities
            if(state.cities.length === 0) {
                // console.log('putting city in queue')
                state.queueCity = payload
            } else {
                // console.log('setting city by id')
                for(var i = 0; i < state.cities.length; i++){
                    if(state.cities[i].id == payload) {
                        // console.log('set city')
                        state.activeCity = state.cities[i]
                        break
                    }
                } 
                // console.log('exhausted city list')
            }
            
        },
        setProjects(state, payload) {
            state.projects = payload;
        },
        setCityData (state, payload) {
            // console.log('setting data with payload:')
            // console.dir(payload)
            Vue.set(state.cityData, payload.key, payload.data)
        },
        setSearchType (state, payload){
            if(payload === "city" || payload === "region" ){
                //console.log('setting search type to: ' + payload)
                state.searchType = payload  
            }
        },
        setTemplate (state, payload) {
            // console.log("setting template to:")
            // console.dir(payload)
            state.template = payload
            // Vue.set(state.template, 0, payload)
        },
        calcRockData (state, payload) {
            // calculate rockstrom data for given city id in payload
            // when the city data is set, this is where we can calculate
            // the rockstrom values for the graph
            // want to have a new array
            // var categories = [
            //     {
            //         'name': cat.name,
            //         'measures': [
            //             'name': meas.name,
            //             'value': avg sum
            //             ]
            //     },
            //     {
            //         ...   
            //     }
            // ]
            if(state.template.length === 0) {
                // if template length is 0, then we are asking to generate
                // a rockstrom before we have loaded the page
                // queue this request, and load after the template
                // is ready
                // console.log('queuing calculation of rockstrom data for: ' + payload)
                state.queueRock = payload
                return
            }
            
            state.queueRock = undefined // clear queue, if any
            
            var rockData = []
            // console.log('calculating rockstrom data')
            // console.dir(state.template)
            for(var i = 0; i < state.template.length; i++){
                var cat = {}
                cat.name = state.template[i].name
                cat.measures = []
                for(var j = 0; j < state.template[i].measures.length; j++) {
                    cat.measures.push({
                        'name': state.template[i].measures[j].name,
                        'value': 0
                    })
                    var sum = 0;
                    var count = 0;
                    for(var k = 0; k < state.template[i].measures[j].indicators.length; k++){
                        // payload.data[state.template[i].measures[j].indicators[k].id] holds city value
                        // state.template[i].measures[j].indicators[k].target holds target
                        // state.template[i].measures[j].indicators[k].relationship holds relationship
                        var cityVal = state.cityData[payload][state.template[i].measures[j].indicators[k].id] ? state.cityData[payload][state.template[i].measures[j].indicators[k].id].value : undefined
                        var targetVal = state.template[i].measures[j].indicators[k].target
                        var tolVal = state.template[i].measures[j].indicators[k].tolerance // for RANGE type indicators
                        var rel = state.template[i].measures[j].indicators[k].relationship
                        if((cityVal && targetVal) && rel !== 'NONE') {
                            // console.log('t: ' + targetVal + ' v: ' + cityVal + ' r:' + rel)
                            count++;
                            if(rel === 'INVERSE') {
                                sum += 1 / (1 + (cityVal - targetVal) / targetVal)
                            } else if(rel === 'DIRECT') {
                                // direct   
                                sum += (1 + (cityVal - targetVal) / targetVal)
                            } else {
                                // range - treat as DIRECT on upper bound (higher is bad), treat as INVERSE on lower bound (lower is bad)
                                // need to use the tolerance here
                                if (cityVal > (targetVal + tolVal)){
                                    // DIRECT
                                    sum += (1 + (cityVal - (targetVal + tolVal)) / (targetVal + tolVal))
                                } else if (cityVal < (targetVal - tolVal)) {
                                    // INVERSE
                                    sum += 1 / (1 + (cityVal - (targetVal - tolVal)) / (targetVal - tolVal))
                                } else {
                                    // within bounds - add 0
                                    sum += 0
                                }
                                
                            }
                        }
                    }
                    if(count > 0) {
                        cat.measures[j].value = sum / count
                    } else {
                        cat.measures[j].value = 0
                    }
                    
                }
                rockData.push(cat)
            }
            // console.log('calculated city rockstrom data for city: ' + payload)
            // console.dir(rockData)
            Vue.set(state.rockstromData, payload, rockData)
        },
        setRankedIndicators (state, payload) {
            for(var i = 0; i < payload.length; i++){
                payload[i].rankings = []
            }
            state.rankedIndicators = payload  
        },
        setRanks (state, payload) {
            state.rankedIndicators[payload.key].rankings = payload.data
            // Vue.set(state.rankedIndicators[payload.key].rankings, payload.key, payload.data)  
        },
        addQuickLink (state, payload) {
            // console.log('adding link')
            // console.dir(payload)
            // avoid duplicate
            for(var i = 0; i < state.quickLinks.length; i++){
                if(state.quickLinks[i].id === payload.id) return
            }
            
            state.quickLinks.push ({
                'name': payload.name,
                'id': payload.id
            })
        },
        dropQuickLink (state, payload) {
            // console.log('dropping link')
            state.quickLinks.splice(payload, 1)
        }
    },
    actions: {
        // async function - actions are called from dispatch
        // fn ({commit, dispatch, state}, payload) {}
        init ({dispatch}) {
          // init called when app mounts to dispacth other actions to configure
          // the app
        //   console.log('initializing app')
          // need to get:
          //    1. city list
          //    2. indicator template
          dispatch('getTemplate')
          dispatch('getCities')
          dispatch('getRankedIndicators')
          
          
        },
        getCities ({commit}) {
            // console.log('getting cities')
            axios.get('/cities')
                .then(res => {
                    // console.log('got cities')
                    // console.dir(res)
                    commit('setCities', res.data.data)
                })
                .catch(err => {
                    console.log('err getting cities')
                    console.dir(err)
                })
        },
        getTemplate ({commit, state}) {
            // console.log('getting template')
            axios.get('/template/indicator')
                .then(res => {
                    // console.log('got template')
                    // console.dir(res)
                    commit('setTemplate', res.data.data)
                    
                    if(state.queueRock) {
                        // if we had a chart queued to load, load it now
                        // since the template is ready
                        commit('calcRockData', state.queueRock)
                    }
                })
                .catch(err => {
                    console.log('error getting template')
                    console.dir(err)
                })
        },
        setCity ({commit}, payload) {
            commit('setActiveCity', payload)
        },
        getCityProjects ({commit, state}) {
			axios.get('/cities/' + state.activeCity.id + '/projects')
				.then(res => {
				// console.log('found projects: ');
					// console.dir(res.data.projects);
					commit('setProjects', res.data.data);
				}).catch(err => {
                    console.log("error with projects")
                    console.dir(err)
				});
        },
        getRankedIndicators ({commit}) {
            axios.get('/indicators/ranked')
                .then(res => {
                    // console.log('got ranked indicators')
                    commit('setRankedIndicators', res.data.data)
                })
                .catch(err => {
                    console.log('error with ranked indicators')
                    console.dir(err)
                })
        },
        getRanks ({commit, state}, payload) {
            axios.get('/indicators/' + state.rankedIndicators[payload].id + '/ranks')
                .then(res => {
                    // console.log('got rankings for indicator: ' + payload)
                    commit('setRanks', {
                        'key': payload,
                        'data': res.data.data
                    })
                })
                .catch(err => {
                    console.log('err getting rankings for indicator: ' + payload)
                    console.dir(err)
                })
        },
        getCityData ({commit, state}, payload) {
            // console.log('getting city data')
            if(!state.cityData[payload]) {
                // initialize the city data as 'loading'
                // to prevent this action from being called multiple times
                // (e.g. if its 'loading', don't dispatch again)
                commit('setCityData', {
                    'key': payload,
                    'data': 'loading'
                })
                // call api
                // console.log('reaching api for city data')
                axios.get('/cities/' + payload + '/indicators')
                    .then(resp => {
                        // console.log('got city data')
                        // console.dir(resp)
                        // set data as teh result
                        commit('setCityData', {
                            'key': payload,
                            'data': resp.data.data
                        })
                        commit('calcRockData', payload) // calculate the rockstrom data for this city
                    })
                    .catch(err => {
                        console.log('could not get city data for city: ' + payload)
                        console.dir(err)
                    })
            }
        }
    }
})
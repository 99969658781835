<template>

    <div style="width:100%;" class="quickLinks" v-if="quickLinks.length > 0" >
        <div class="bookmark">
            <img v-b-popover.hover.left="'City Quick Links'" style="height:20px;" src="../assets/bookmark-outline.png" />
        </div>
        <div class="text-center quickLinksItem"  v-for="(q,i) in quickLinks" :key="q.id">
            
            <b-badge @click="loadPage(q.id)" class="link-like" :variant="currentCity.id === q.id ? 'primary' : 'light'">{{ q.name }}</b-badge>
            <span class="float-right link-like" style="color:maroon;" @click="dropLink(i)">&times;</span> 
        </div>
    </div>
</template>

<script>
    
    export default {
        computed: {
            quickLinks () {
                return this.$store.getters.getQuickLinks
            },
            activeCity () {
                return this.$store.getters.getActiveCity
            },
            currentCity () {
                if(this.activeCity === undefined) {
                    return {
                        'id': 0,
                        'name': 'Loading'
                    }
                } else {
                    return this.activeCity
                }
            }
        },
        methods: {
            dropLink (index) {
                this.$store.commit('dropQuickLink', index)
            },
            loadPage (index) {
                this.$router.push('/city/' + index)
            }
        }
    }
</script>

<style scoped>
    .quickLinks {
        margin: 0 !important;
        position: sticky;
        top: 0;
        z-index: 10;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        
        background: lightgray;
        border-bottom: 1px solid black;
    }
    
    .quickLinksItem {
        margin-right: 10px;
    }
    
    .bookmark {
        /*position: absolute;*/
        /*left: 30px;*/
        order: 0;
        padding-right: 20px;
        
    }
  
</style>
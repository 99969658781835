<template>
    <div>
        <div v-if="ready" class="buttonContainer">
            <b-btn @click="selectedIndicator=i" 
                class="buttonItem" 
                :variant="selectedIndicator==i ? 'primary' : 'outline-secondary'" 
                v-for="(r, i) in rankedIndicators" 
                :key="r.id">{{ r.name }}</b-btn>
        </div>
        <div v-else>
            Loading... 
        </div>
        <hr>
        <div class="text-center" v-if="ready && !drawing">
            <h6 v-if="selectedIndicator > -1">Rankings for: {{rankedIndicators[selectedIndicator].name}}</h6>
            <h6 v-else>Please select an indicator to view city rankings.</h6>
        </div>
        <div class="d3tooltip"></div>
        
        <div class="graphContainer">
            <div class="rankContent" v-if="ready && !drawing" id="graphArea">
            </div>
        </div>
        
        <div v-if="drawing" class="text-center">
            <h6>Loading...</h6>
        </div>
       
        
        <app-footer position='' />
    </div>
</template>

<script>
    import Footer from './Footer.vue'
    import * as d3 from 'd3'
    // import { select } from 'd3-selection'
    // import transition from 'd3-transition'
    
    export default {
        components: {
            'appFooter': Footer
        },
        data () {
            return {
                selectedIndicator: -1,
                waiting: false,
                drawing: false
            }  
        },
        watch: {
            selectedIndicator (to, from) {
                if(to !== from) {
                    this.waiting = false // click a new rank - load it
                    if(this.ready && this.selectedIndicator > -1 && this.rankedIndicators[this.selectedIndicator].rankings) {
                        if(this.rankedIndicators[this.selectedIndicator].rankings.length > 0) {
                            // console.log('already have ranks for this indicator')
                            
                            this.setupChart()
                            
                            return
                        } else {
                            if(!this.waiting){ // avoid multiple calls
                                // console.log('loading ranks')
                                this.$store.dispatch('getRanks', this.selectedIndicator)
                                // this.setupChart()
                                this.waiting = true
                            }
                            // console.log('already waiting for this indicator')
                            return // waiting for response already    
                        }
                    }
                    // console.log('not ready')
                    return
                }
            },
            haveRanks (to) {
                if(to) {
                    this.setupChart()
                } // if from was also true, then the chart is drawn from the watch on selectedIndicator
            }
        },
        computed: {
            rankedIndicators () {
                return this.$store.getters.getRankedIndicators
            },
            ready () {
                return this.rankedIndicators.length > 0
            },
            haveRanks () {
                if(this.ready && this.selectedIndicator > -1 && this.rankedIndicators[this.selectedIndicator].rankings) {
                    if(this.rankedIndicators[this.selectedIndicator].rankings.length > 0) {
                        return true
                    } else {
                        return false    
                    }
                }
                return false
            }
        },
        methods: {
            setupChart () {
                this.drawing = true
                var selectedData = this.rankedIndicators[this.selectedIndicator].rankings
                if(!selectedData) {
                    // console.log('aborting graph - no data!')
                    return
                    
                }
                
                // console.log('drawing chart');
                // console.log('data:');
                // console.dir(selectedData);
                d3.select('#rankingChart').remove()
                d3.select('svg').remove()
                d3.select('#graphArea').html("")  // clear old content
                                            // assumes svg exists
                var barHeight = 10;
                var barSpacing = 1;
                var width = 800;
                var height = selectedData.length * (barHeight + barSpacing); // 5px per bar + 1px space
                var barOffset = 200;
            
                // sort the data
                // console.dir(selectedData);
                if( this.rankedIndicators[this.selectedIndicator].relationship == "INVERSE"){
                    // console.log('inverse graph')
                    // inverse --> higher is better
                    
                    
                } else {
                    // direct --> lower is better
                    // console.log('direct graph')
                    
                    if(selectedData[0].value > selectedData[selectedData.length - 1].value) {
                        // console.log('reversing')
                        selectedData.reverse() // data comes sorted high to low    
                    }
                }
            
                var currentRank = 1
                var actualRank = 1
                var maxVal = 0;
                for(var i = 0; i < selectedData.length; i++) {
                  selectedData[i].rank = currentRank
                  actualRank++
                  
                  if(i < selectedData.length - 1){
                    if(selectedData[i].value !== selectedData[i+1].value) {
                        currentRank = actualRank
                    } else {
                        // do nothing
                    }
                  }
            
                  if(selectedData[i].value > maxVal) {
                    maxVal = selectedData[i].value;
                  }
                }
                
                // console.log('max val: ' + maxVal)
            
                function scale(val) {
                  return (val / maxVal) * (width - barOffset);
                }
            
                var yCount = 0;
                function setY() {
                  // console.log('ycount: ' + yCount);
                  var yPosition = yCount * (barHeight + barSpacing);
                  yCount++;
                  return yPosition;
                }
            
                // console.dir(element[0]); $('.chartContainer')
                var svg = d3.select('#graphArea').append("svg")
                    .attr("id", "rankingChart")
                    .attr("width", width + 'px')
                    .attr("height", height + 'px')
                    .style("background-color", "rgba(0,0,0,0)");
                    // .style("background-color", "#FFFFFF");
            
                var graph = svg.append("g")
                                .attr("width", width)
                                .attr("height", height);
            
                graph.append("line")
                        .style("stroke", "black")
                        .attr("x1", barOffset)
                        .attr("x2", barOffset)
                        .attr("y1", 0)
                        .attr("y2", height);
            
                // var tooltip = d3.select("#chart")
                //         .append('div')
                //         .attr('class', 'd3tooltip')
                //         .html("test test test")
                //         .style('opacity', 1);
            
                for(i = 0; i < selectedData.length; i++){
                    // console.log('appending text...')
                  // selectedData[i];
                  // var cityName = (() => { return selectedData[i].name })();
                  // var cityVal = (() => { return selectedData[i].value } )();
                  // write city name
                  graph.append("text")
                      .attr("text-anchor", "end")
                      .attr("x", 190)
                      .attr("y", i * (barHeight + barSpacing) + (barHeight - barSpacing))
                      .attr("font-family", 'sans-serif')
                      .attr("font-size", '10px')
                      .text(selectedData[i].name + ', ' + selectedData[i].country);
            
                  // draw tick for the city
                  graph.append("line")
                      .attr("stroke", "black")
                      .attr("x1", barOffset - 5)
                      .attr("x2", barOffset)
                      .attr("y1", i * (barHeight + barSpacing) + barHeight / 2)
                      .attr("y2", i * (barHeight + barSpacing) + barHeight / 2);
            
                  // create city bar
                  // graph.append("rect")
                  //     .attr("x", barOffset)
                  //     .attr("y", i * (barHeight + barSpacing))
                  //     .attr("width", scale(selectedData[i].value))
                  //     .attr("height", barHeight)
                  //     .attr("fill", 'rgb(0,60,113)');
            
                }
            
                graph.selectAll('g')
                  .data(selectedData)
                  .enter()
                  .append('rect')
                  // .attr('x', function(d){return x(d.value)})
                  .attr('x', barOffset)
                  .attr('y', () => {return setY()})
                  .attr('height', barHeight)
                  .attr('width', (d) => {return scale(d.value)})
                  .attr("class", "bar")
                  .on('mouseover', (event, d) => {
                    // this.hoverCity = d.name;
                    // console.log('d: ');
                    // console.dir(d);
                    // console.dir(tooltip);
                    d3.select(".d3tooltip").transition()
                      .duration(150)
                      .style('opacity', 1);
                    d3.select(".d3tooltip").html(d.rank + '. ' + d.name + ': ' + d.value)
                      // .style("left", "300px")
                      // .style("top", "300px");
                      .style("left", (event.pageX + 10) + "px")
                      .style("top", (event.pageY - 15) + "px");
                  })
                  .on('mouseout', () => {
                    // this.hoverCity = undefined;
                    d3.select(".d3tooltip").transition()
                      .duration(50)
                      .style('opacity', 0);
                  });
                this.drawing = false
            }
        },
        created() {
            // console.log('rank page created')
                
            
        },
        destroyed () {
            // console.log('rank page destroyed')
            
        }
    }
</script>

<style>
    .buttonContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        
    }
    
    .buttonItem {
        margin-right: 20px;
        margin-top: 10px;
    }
    
    .graphContainer {
        position: relative;
        text-align: center;
    }
    
    .rankContent {
        display: inline-block;
        margin: 0 auto;
        padding: 20px;
    }
    
    .d3tooltip {
        position: absolute;
        padding: 10px;
        pointer-events: none;
        border-radius: 5px;
        background: white;
        color: rgb(0,60,113);
        border: 1px solid black;
        opacity: 0;
        
        z-index: 200;
    }
    
    .bar {
        fill: rgb(0,60,113);
    }
    
    .bar:hover {
        fill: lightskyblue;
    }

</style>
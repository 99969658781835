<template>
    <div id="chartContainer" style="width:100%;position:relative;">
        <div :id="'chart'">
        </div>
        <div :id="'D3toolTip'"></div>
        <div style="text-align:center;">
          <a class="btn btn-sm btn-outline-secondary" @click="saveSVG">Save (SVG)</a>&nbsp;
          <a class="btn btn-sm btn-outline-secondary" @click="savePNG">Save (PNG)</a>
        </div>
        
    </div>
</template>

<script>
    import * as d3 from 'd3'
    // import { select } from 'd3-selection'
    // import transition from 'd3-transition'
    
    export default {
        data () {
          return {
              chartWidth: 400,
              chartHeight: 400
          }  
        },
        props: [
            'elId', 'index' // elId: city index, index: category index (0 or 1)
        ],
        computed: {
            activeCity () {
                return this.$store.getters.getActiveCity
            },
            template () {
                return this.$store.getters.getTemplate
            },
            selectedCategory () {
                return this.index === 0 ? "Bio-Physical" : "Socioeconomic";
            },
            rockData () {
                return this.$store.getters.getRockData[this.elId][this.index].measures
            }
        },
        watch: {
            index (to, from) {
                if(to !== from) {
                    this.setupChart()
                }
            },
            elId (to, from) {
                if(to !== from) { // id changed - delete old contents
                    this.setupChart()
                }
            }
        },
        methods: {
            setupChart () {
                // setting up chart
                var vm = this

                var pie = d3.pie()
                    .sort(null)
                    .value(function (d) {
                        // console.log("weight: " + d.weight);
                        return +d.weight;
                        // return d.weight;
                    });
            
                var width = this.chartWidth;
                var height = this.chartHeight;
                var padding = .15 * Math.min(width, height);
            
                var dataRadius = (width/2) - padding; //
            
            
                d3.select('#chart').html(""); // clear old content

                var svg = d3.select('#chart').append("svg")
                    .attr("id", "ngRockstromChart-" + vm.elId)
                    .attr("width", width + 'px')
                    .attr("height", height + 'px')
                    .attr("class", "rockstrom")
                    .style("background-color", "rgba(0,0,0,0)");
            
                svg.append("ellipse")
                    .attr('rx', dataRadius + dataRadius/6)
                    .attr('ry', dataRadius + dataRadius/6)
                    .attr('cx', width / 2)
                    .attr('cy', height / 2)
                    .style("stroke", "transparent")
                    // .style("stroke", "#FFFFFF")
                    .style("stroke-width", 4)
                    .style("fill", "url(#radlgrad)");
            
                // move center point of graph
                svg = d3.select('#ngRockstromChart-' + vm.elId).append("g")
                    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
            
                var defs = svg.append('defs');
            
                var radialGradient = defs.append('radialGradient')
                    .attr('id', 'radlgrad')
                    .attr('cx', '50%').attr('cy', '50%').attr('r', '50%').attr('fx', '50%').attr('fy', '50%');
                radialGradient.append("stop").attr("offset", "20%").style("stop-color", "rgb(255,255,0)").style("stop-opacity", "1.0");
                radialGradient.append("stop").attr("offset", "40%").style("stop-color", "rgb(255,200,0)").style("stop-opacity", "1.0");
                radialGradient.append("stop").attr("offset", "50%").style("stop-color", "rgb(255,180,0)").style("stop-opacity", "1.0");
                radialGradient.append("stop").attr("offset", "60%").style("stop-color", "rgb(255,150,0)").style("stop-opacity", "1.0");
                radialGradient.append("stop").attr("offset", "80%").style("stop-color", "rgb(255,0,0)").style("stop-opacity", "1.0");
                radialGradient.append("stop").attr("offset", "100%").style("stop-color", "rgb(255,255,255)").style("stop-opacity", "1.0");

                var index = 0;
                vm.rockData.forEach(function (d) {
                    d.index = index;
                    d.weight = 1; // even sizes on pie chart
                    index++;
                });

                if(d3.select('#D3toolTip') == null) {
                  d3.select('body')
                    .append("div")
                    .attr('id', 'D3toolTip')
                    .style("visibility", "hidden")
                    .style("top", "0px")
                    .style("left", "0px")
                    .text("");
                }

                // if(d3.select('#D3toolTip')[0] == null) {
                //   alert('outer is null')
                //   var tooltip = d3.select('body')
                //     .append("div")
                //     .attr('id', 'D3toolTip')
                //     .style("visibility", "hidden")
                //     .style("top", "0px")
                //     .style("left", "0px")
                //     .text("");
                // } else {
                //   if(d3.select('#D3toolTip')[0][0] == null) {
                //     alert('inner is null')
                //     var tooltip = d3.select('body')
                //       .append("div")
                //       .attr('id', 'D3toolTip')
                //       .style("visibility", "hidden")
                //       .style("top", "0px")
                //       .style("left", "0px")
                //       .text("");
                //   } else {
                //     // console.log('not recreating tooltip')
                //   }
                // }
            

            
                function scale(value) {
                    // dataRadius is (radius - padding);
                    return dataRadius * value / 5; // fixed at 5 rings
                    // actually 6 rings wide but the 6th ring is the "extra"
                }
            
            
                var hideOuterRedCircle = d3.arc()
                  .outerRadius(scale(6))
                  // .innerRadius(scale(5.9));
                  .innerRadius(function(d) {
                    // console.log(scale(d.data.value));
                    return (scale(d.data.value) > scale(5) ? scale(5.9) : scale(5) );
                  });
          
                var pieBordersWhite = d3.arc()
                    .outerRadius(function () {
                        // return scale(maxValue);
                        return scale(5);
                    })
                    .innerRadius(0);
            
                var textCircle = d3.arc()
                    .outerRadius(function () {
                        // return scale(maxValue) + 10;
                        return scale(5.1);
                    })
                    .innerRadius(0);
            
                var ring1 = d3.arc()
                  .outerRadius(scale(1))
                  .innerRadius(scale(0));
            
                var ring2 = d3.arc()
                  .outerRadius(scale(2))
                  .innerRadius(scale(0));
            
                var ring3 = d3.arc()
                  .outerRadius(scale(3))
                  .innerRadius(scale(0));
            
                var ring4 = d3.arc()
                  .outerRadius(scale(4))
                  .innerRadius(scale(0));
            
                var inverseDataArcs = d3.arc()
                    .outerRadius(scale(5))
                    .innerRadius(function (d) {
                      // prevent negative radius
                        return Math.min(scale(d.data.value),scale(5));
                    });
            
                var hazyZone = d3.arc()
                    .outerRadius(scale(5.9))
                    .innerRadius(scale(0));
            
                // darker middle circle around the green area
                // FILL
                svg.append("ellipse")
                    .attr('rx', scale(1.0))
                    .attr('ry', scale(1.0))
                    .attr('cx', 0)
                    .attr('cy', 0)
                    .style("stroke", "transparent")
                    .style("fill", "rgba(0,255,0,0.4)");
            
                var g = svg.selectAll(".arc")
                    .data(pie(vm.rockData))
                    .enter()
                    .append("g")
                    .attr("class", "arc");
            
                g.append("path")
                    .attr("d", ring1)
                    .attr('class', 'conRing')
                    .attr('ringNum', '1')
                    .style("stroke", "transparent")
                    .style("stroke-width", 0)
                    .style("fill", "transparent")
            
                g.append("path")
                    .attr("d", ring2)
                    .attr('class', 'conRing')
                    .attr('ringNum', '2')
                    .style("stroke", "transparent")
                    .style("stroke-width", 0)
                    .style("fill", "transparent")

                g.append("path")
                    .attr("d", ring3)
                    .attr('class', 'conRing')
                    .attr('ringNum', '3')
                    .style("stroke", "transparent")
                    .style("stroke-width", 0)
                    .style("fill", "transparent")
            
                  g.append("path")
                      .attr("d", ring4)
                      .attr('class', 'conRing')
                      .attr('ringNum', '4')
                      .style("stroke", "transparent")
                      .style("stroke-width", 0)
                      .style("fill", "transparent")
            
                  d3.selectAll('.conRing')
                    .each(function() {
                      var lineStyle = "2,2,2,2";
                      var lineWeight = 0.5;

                      if(d3.select(this).attr("ringNum") !== '1') {
                        lineStyle = "2,2,2,2";
                        lineWeight = 0.5;
                      } else {
                        lineStyle = "2,0,2,0";
                        lineWeight = 1.5;
                      }

                      var firstArcSection = /(^.+?)L/;
                      //Grab everything up to the first Line statement
                      var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
                      //Replace all the commas so that IE can handle it
                      newArc = newArc.replace(/,/g , " ");
            
                      svg.append("path")
                        .attr("d", function() {
                          return newArc;
                        })
                        .attr("class", "cRing")
                        .style("stroke", "#000")
                        .style("stroke-width", lineWeight)
                        .style("stroke-dasharray", lineStyle)
                        .style("pointer-events", "none")
                        .style("fill", "transparent")
                    });
            
                    // clean up the temp rings
                d3.selectAll('.conRing').remove();
            
                g.append("path")
                    .attr("d", pieBordersWhite)
                    .style("stroke", "#000000")
                    .style("stroke-width", 0.5)
                    .style("fill", "transparent");
            
            
                g.append("path")
                    .attr("d", textCircle)
                    .attr('class', 'defaultTextPath')
                    .attr("id", function (d) {
                        // console.log("defined as: path" + d.data.index);
                        return "path" + d.data.index;
                    })
                    .style("stroke", "transparent")
                    .style("fill", "transparent")
                    .append("svg:title")
                    .text(function(d) { return "Value: " + d.data.value });
            
                svg.selectAll('.defaultTextPath')
                  .each(function(d,i) {
                    // text flipping from : http://www.visualcinnamon.com/2015/09/placing-text-on-arcs.html
                    //Search pattern for everything between the start and the first capital L
                    var firstArcSection = /(^.+?)L/;
            
                    //Grab everything up to the first Line statement
                    var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
                    //Replace all the commas so that IE can handle it
                    newArc = newArc.replace(/,/g , " ");
            
                    //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                    //flip the end and start position
                    d.flipped = false;
                    if (d.startAngle > Math.PI/2 && d.startAngle < 4) {
                      var startLoc 	= /M(.*?)A/,		//Everything between the capital M and first capital A
                          middleLoc 	= /A(.*?)0 0 1/,	//Everything between the capital A and 0 0 1
                          endLoc 		= /0 0 1 (.*?)$/;	//Everything between the 0 0 1 and the end of the string (denoted by $)
                      //Flip the direction of the arc by switching the start and end point (and sweep flag)
                      var newStart = endLoc.exec( newArc )[1];
                      var newEnd = startLoc.exec( newArc )[1];
                      var middleSec = middleLoc.exec( newArc )[1];
            
                      //Build up the new arc notation, set the sweep-flag to 0
                      newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                      d.flipped = true;
                    }//if
            
                    //Create a new invisible arc that the text can flow along
                    svg.append("path")
                      .attr("class", "hiddenTextPath")
                      .attr("id", "correctedText"+i)
                      .attr("d", newArc)
            
                      .style("fill", "none");
            
                  });
            
                g.append("path")
                    .attr('class', 'arcHover')
                    .attr("d", hazyZone)
                    .attr("id", (d) => {
                      return "hazy" + d.data.index + '-' + vm.elId;
                    })
                    .style("stroke", "transparent")
                    .style("fill", "transparent")
                    .on('mouseover', (event, d) => { mouseOver(event, d, vm.elId) } )
                    .on('mouseout', (event, d) => { mouseOut(event, d, vm.elId) } ) ;
            
                  g.append("path")
                      .attr("d", inverseDataArcs)
                      .style("stroke", "#000000")
                      .style("stroke-width", 0.5)
                      .style("fill", "white")
                      .on('mouseover', (event, d) => { mouseOver(event, d, vm.elId) } )
                      .on('mouseout', (event, d) => { mouseOut(event, d, vm.elId ) } ) ;

                  g.append("path")
                      .attr("d", hideOuterRedCircle)
                      // .style("stroke", "#000000")
                      .style("stroke-width", 0.5)
                      .style("fill", "#FEFEFE")
                      .on('mouseover', (event, d) => { mouseOver(event, d, vm.elId) } )
                      .on('mouseout', (event, d) => { mouseOut(event, d, vm.elId) } ) ;
            
                  g.append("text")
                      .attr('font-size', function(d) {
                        // sizing is designed for width 480
            
                        // scale down based on size of graph
                        var sSize = Math.min(width / 600, 1.0);
            
                        // scale down based on length of label
                        // scale based on 22 limit
                        var lSize = 1.0;
                        if(d.data.name.length > 22) {
                          lSize = Math.min(1-Math.abs(d.data.name.length - 22)/22, 1.0);
                        }
            
                        var fSize = Math.min(sSize, lSize);
                        return fSize + 'em';
                      })
                      .attr('dy', function(d) {
                        if(d.flipped) {
                          return 12;
                        } else {
                          return 0;
                        }
                      })
                      .append("textPath")
                      .attr("xlink:href", function(d) {
                          return "#correctedText" + d.data.index;
                      })
                      .attr("text-anchor", "middle")
                      .attr("startOffset", '50%')
            
                      .text(function (d) {
                          return d.data.name;
                      })
                      .on('mouseover', (event, d) => { mouseOver(event, d, vm.elId) } )
                      .on('mouseout', (event, d) => { mouseOut(event, d, vm.elId) } ) ;
          
                function mouseOver(event, d, id) {
                  if(d === undefined) {
                    return;
                  }

                  //var xPosOffset = xPos - d3.select('#D3toolTip').offsetWidth/2;
                  d3.select('#hazy'+d.data.index+'-'+id).style("fill", "#003c71");
                  d3.select('#D3toolTip')
                    .html('<b>' + d.data.name + '</b><br><i>' + d.data.value.toFixed(2) + '</i>' ); // round to 2 decimal

                  // update coordinates, which depend on html (as it changes size of tooltip)
                  var mouse = d3.pointer(event);
                  var xPos = mouse[0];
                  var yPos = mouse[1];

                  var xOff = document.getElementById('ngRockstromChart-'+id).getBoundingClientRect().x;
                  xOff += document.getElementById('D3toolTip').getBoundingClientRect().width;
                  var yOff = document.getElementById('ngRockstromChart-'+id).getBoundingClientRect().y;
                  yOff += document.getElementById('D3toolTip').getBoundingClientRect().height;
                  
                  var xPosFinal = xPos + xOff
                  var yPosFinal = yPos + yOff
                  d3.select('#D3toolTip')
                    .transition(200)
                    .style('visibility', 'visible')
                    .style('left', (xPosFinal) + 'px')
                    .style('top', (yPosFinal) +'px');
                }
            
                function mouseOut(event, d, id) {
                  if(d === undefined) {
                    return;
                  }
            
                  d3.select('#hazy'+d.data.index+'-'+id).style("fill", "transparent");
            
                  d3.select('#D3toolTip')
                    .transition(200)
                    .style('visibility', 'hidden');
                }
            
                d3.select("div.chart").style('width', width);

            },
            saveSVG () {
                var cityName = this.activeCity;
                //console.log("getting svg by id: " + this.elId);
                const svg = document.getElementById('chart').innerHTML;
                const blob = new Blob([svg.toString()]);
                const element = document.createElement("a");
                element.download = "Rockstrom Chart for " + cityName.name.trim() + " " + cityName.country.trim() + " (" + this.selectedCategory + ").svg"; 
                element.href = window.URL.createObjectURL(blob);
                element.click();
                element.remove();
            },
            savePNG () {
                var cityName = this.activeCity;
                var vm = this;

                var doctype = '<?xml version="1.0" standalone="no"?>'
                + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
                var source = (new XMLSerializer()).serializeToString(d3.select("svg").node());
                var blob = new Blob([ doctype + source], { type: 'image/svg+xml;charset=utf-8' }); 
                var url = window.URL.createObjectURL(blob);
                var img = d3.select('body').append('img')
                    .attr('width', this.chartWidth)
                    .attr('height', this.chartHeight)
                    .attr('class', 'tempIMG')
                    .node();


                img.onload = function(){
                    // Now that the image has loaded, put the image into a canvas element.
                    var canvas = d3.select('body').append('canvas').attr('class', 'tempIMG').node();
                    canvas.width = vm.chartWidth;
                    canvas.height = vm.chartHeight;
                    var ctx = canvas.getContext('2d');
                    ctx.fillStyle = "white";
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0);
                    var canvasUrl = canvas.toDataURL("image/png");
                    // this is now the base64 encoded version of our PNG! you could optionally 
                    // redirect the user to download the PNG by sending them to the url with 
                    // `window.location.href= canvasUrl`.
                    var a = document.createElement('a');
                    a.href = canvasUrl;
                    a.download = "Rockstrom Chart for " + cityName.name.trim() + " " + cityName.country.trim() + " (" + vm.selectedCategory + ").png"; 
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    d3.select('.tempIMG').remove();
                    canvas.remove();
                }
              // start loading the image.
              img.src = url;
            }	
        },
        mounted () {
            this.setupChart()
        }
    }
</script>

<style>
    .rockstrom {
        display: block;
        margin: 0 auto;
    }
    
    .tempIMG {
      visibility: hidden;
      display: none;
    }


    #D3toolTip {
      position: absolute; 
      background: white;
      font-size: 0.8em;
      border: 1px solid black;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      text-align: center;
      color: #003c71;
      padding: 10px;
      pointer-events: none;
    }

</style>